// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-dotted {
    border-style: dotted;
}

.border-dashed {
    border-style: dashed;
}

.border-solid {
    border-style: solid;
}

.border-double {
    border-style: double;
}

.border-groove {
    border-style: groove;
}

.border-ridge {
    border-style: ridge;
}

.border-inset {
    border-style: inset;
}

.border-outset {
    border-style: outset;
}

.border-none {
    border-style: none;
}

.border-hidden {
    border-style: hidden;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/border.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".border-dotted {\r\n    border-style: dotted;\r\n}\r\n\r\n.border-dashed {\r\n    border-style: dashed;\r\n}\r\n\r\n.border-solid {\r\n    border-style: solid;\r\n}\r\n\r\n.border-double {\r\n    border-style: double;\r\n}\r\n\r\n.border-groove {\r\n    border-style: groove;\r\n}\r\n\r\n.border-ridge {\r\n    border-style: ridge;\r\n}\r\n\r\n.border-inset {\r\n    border-style: inset;\r\n}\r\n\r\n.border-outset {\r\n    border-style: outset;\r\n}\r\n\r\n.border-none {\r\n    border-style: none;\r\n}\r\n\r\n.border-hidden {\r\n    border-style: hidden;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
