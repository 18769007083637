// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Colors */
.primary {
    color: #17151D;
}

/* Backgrounds */
.primary-background {
    background-color: #17151D;
}

.text-blue {
    color: #003DB7;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/color.css"],"names":[],"mappings":"AAAA,WAAW;AACX;IACI,cAAc;AAClB;;AAEA,gBAAgB;AAChB;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB","sourcesContent":["/* Colors */\r\n.primary {\r\n    color: #17151D;\r\n}\r\n\r\n/* Backgrounds */\r\n.primary-background {\r\n    background-color: #17151D;\r\n}\r\n\r\n.text-blue {\r\n    color: #003DB7;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
