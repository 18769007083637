// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mb-2-px {
    margin-bottom: 2px;
}

.mb-3-px {
    margin-bottom: 3px;
}

.mb-4-px {
    margin-bottom: 4px;
}

.mb-5-px {
    margin-bottom: 5px;
}

.mt-2-px {
    margin-top: 2px;
}

.mt-3-px {
    margin-top: 3px;
}

.mt-4-px {
    margin-top: 4px;
}

.mt-5-px {
    margin-top: 5px;
}

.ml-2-px {
    margin-left: 2px;
}

.ml-3-px {
    margin-left: 3px;
}

.ml-4-px {
    margin-left: 4px;
}

.ml-5-px {
    margin-left: 5px;
}

.mr-2-px {
    margin-right: 2px;
}

.mr-3-px {
    margin-right: 3px;
}

.mr-4-px {
    margin-right: 4px;
}

.mr-5-px {
    margin-right: 5px;
}

.mr-20-px {
    margin-right: 20px;
}

.mt-25-px {
    margin-top: 25px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/margin.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".mb-2-px {\r\n    margin-bottom: 2px;\r\n}\r\n\r\n.mb-3-px {\r\n    margin-bottom: 3px;\r\n}\r\n\r\n.mb-4-px {\r\n    margin-bottom: 4px;\r\n}\r\n\r\n.mb-5-px {\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.mt-2-px {\r\n    margin-top: 2px;\r\n}\r\n\r\n.mt-3-px {\r\n    margin-top: 3px;\r\n}\r\n\r\n.mt-4-px {\r\n    margin-top: 4px;\r\n}\r\n\r\n.mt-5-px {\r\n    margin-top: 5px;\r\n}\r\n\r\n.ml-2-px {\r\n    margin-left: 2px;\r\n}\r\n\r\n.ml-3-px {\r\n    margin-left: 3px;\r\n}\r\n\r\n.ml-4-px {\r\n    margin-left: 4px;\r\n}\r\n\r\n.ml-5-px {\r\n    margin-left: 5px;\r\n}\r\n\r\n.mr-2-px {\r\n    margin-right: 2px;\r\n}\r\n\r\n.mr-3-px {\r\n    margin-right: 3px;\r\n}\r\n\r\n.mr-4-px {\r\n    margin-right: 4px;\r\n}\r\n\r\n.mr-5-px {\r\n    margin-right: 5px;\r\n}\r\n\r\n.mr-20-px {\r\n    margin-right: 20px;\r\n}\r\n\r\n.mt-25-px {\r\n    margin-top: 25px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
