// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overflow-hidden {
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
}

.overflow-visible {
    overflow: visible;
}

.overflow-scroll {
    overflow: scroll;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-x-visible {
    overflow-x: visible;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-y-visible {
    overflow-y: visible;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

body:has(.sidebar__scroll),
body:has(.countrycode__dropdown),
body:has(.project__upgradwrapper-popup) .setting-section-right,
body:has(.project__upgradwrapper-popup) .billing__history-wrapper,
body:has(.billing__address-sidebar) .billing__history-wrapper {
    overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/overflow.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;;;;IAKI,gBAAgB;AACpB","sourcesContent":[".overflow-hidden {\r\n    overflow: hidden;\r\n}\r\n\r\n.overflow-auto {\r\n    overflow: auto;\r\n}\r\n\r\n.overflow-visible {\r\n    overflow: visible;\r\n}\r\n\r\n.overflow-scroll {\r\n    overflow: scroll;\r\n}\r\n\r\n.overflow-x-hidden {\r\n    overflow-x: hidden;\r\n}\r\n\r\n.overflow-x-auto {\r\n    overflow-x: auto;\r\n}\r\n\r\n.overflow-x-visible {\r\n    overflow-x: visible;\r\n}\r\n\r\n.overflow-x-scroll {\r\n    overflow-x: scroll;\r\n}\r\n\r\n.overflow-y-hidden {\r\n    overflow-y: hidden;\r\n}\r\n\r\n.overflow-y-auto {\r\n    overflow-y: auto;\r\n}\r\n\r\n.overflow-y-visible {\r\n    overflow-y: visible;\r\n}\r\n\r\n.overflow-y-scroll {\r\n  overflow-y: scroll;\r\n}\r\n\r\nbody:has(.sidebar__scroll),\r\nbody:has(.countrycode__dropdown),\r\nbody:has(.project__upgradwrapper-popup) .setting-section-right,\r\nbody:has(.project__upgradwrapper-popup) .billing__history-wrapper,\r\nbody:has(.billing__address-sidebar) .billing__history-wrapper {\r\n    overflow: hidden;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
