// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.cursor-nodrop {
    cursor: no-drop;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.cursor-wait {
    cursor: wait;
}

.cursor-progress {
    cursor: progress;
}

.cursor-cell {
    cursor: cell;
}

.cursor-crosshair {
    cursor: crosshair;
}

.cursor-text {
    cursor: text;
}

.cursor-all-scroll {
    cursor: all-scroll;
}

.pointer-event-none {
    pointer-events: none;
}

.cursor-grab {
    cursor: grab;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/cursor.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".cursor-pointer {\r\n    cursor: pointer;\r\n}\r\n\r\n.cursor-default {\r\n    cursor: default;\r\n}\r\n\r\n.cursor-nodrop {\r\n    cursor: no-drop;\r\n}\r\n\r\n.cursor-not-allowed {\r\n    cursor: not-allowed;\r\n}\r\n\r\n.cursor-wait {\r\n    cursor: wait;\r\n}\r\n\r\n.cursor-progress {\r\n    cursor: progress;\r\n}\r\n\r\n.cursor-cell {\r\n    cursor: cell;\r\n}\r\n\r\n.cursor-crosshair {\r\n    cursor: crosshair;\r\n}\r\n\r\n.cursor-text {\r\n    cursor: text;\r\n}\r\n\r\n.cursor-all-scroll {\r\n    cursor: all-scroll;\r\n}\r\n\r\n.pointer-event-none {\r\n    pointer-events: none;\r\n}\r\n\r\n.cursor-grab {\r\n    cursor: grab;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
