// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.position-ab {
    position: absolute;
}

.position-re {
    position: relative;
}

.position-fi {
    position: fixed;
}

.position-st {
    position: static;
}

.position-in {
    position: inherit;
}

.position-ini {
    position: initial;
}

.position-un {
    position: unset;
}

.position-sti {
    position: sticky;
}

.position-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/assets/css/position.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC","sourcesContent":[".position-ab {\r\n    position: absolute;\r\n}\r\n\r\n.position-re {\r\n    position: relative;\r\n}\r\n\r\n.position-fi {\r\n    position: fixed;\r\n}\r\n\r\n.position-st {\r\n    position: static;\r\n}\r\n\r\n.position-in {\r\n    position: inherit;\r\n}\r\n\r\n.position-ini {\r\n    position: initial;\r\n}\r\n\r\n.position-un {\r\n    position: unset;\r\n}\r\n\r\n.position-sti {\r\n    position: sticky;\r\n}\r\n\r\n.position-center {\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
