// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-decoration-none {
    text-decoration: none;
}

.text-transform-uppercase {
    text-transform: uppercase;
}

.text-alian-left {
    text-align: left!important;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/text.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".text-decoration-none {\r\n    text-decoration: none;\r\n}\r\n\r\n.text-transform-uppercase {\r\n    text-transform: uppercase;\r\n}\r\n\r\n.text-alian-left {\r\n    text-align: left!important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
